<template>
  <div class="pr-6">
    <div v-if="!isPropertySelected">
      <div class="pt-0">
        <hb-empty-state :message="'Please select a Property to continue.'" :showBtn="false">
        </hb-empty-state>
      </div>
    </div>
    <div v-else>
      <div class="content-view pa-0 mt-0">
        <hb-form
          label="Accounting Template"
          editable
          extra-large
          @cancel="handleEmittedCancel"
          @save="handleEmittedSave"
          :active.sync="active"
          :empty="!isPropertySelected"
        >
          <template v-slot:display>
            {{ propertyAccountingTemplate.name }}
          </template>
          <template v-slot:edit>
            <HbAutocomplete
              v-model="propertyTemplate.id"
              :items="accountingTemplates"
              item-text="name"
              item-value="id"
              v-validate="'required'"
              data-vv-scope="default"
              data-vv-name="propertyTemplate"
              data-vv-as="propertyTemplate"
              :error="errors.has('default.propertyTemplate')"
              placeholder="Select Template"
              :clearable="false"
            />
          </template>
        </hb-form>
        <hb-form label="Book Type" extra-large>
          <template v-slot:display>
            <span class="text-capitalize">
              {{ selectedTemplate.AccountingSetup.description }}
            </span>
          </template>
        </hb-form>
        <hb-form label="Default COA" extra-large full>
          <template v-slot:display>
            <hb-data-table :headers="headers" :items="defaultCOAData">
              <template v-slot:item.gl_subtype_name="{ item }">{{
                item.gl_subtype_name
              }}</template>
              <template v-slot:item.gl_account_code="{ item }">
                <span v-if="item.gl_account_code"
                  >{{ item.gl_account_code }} : {{ item.gl_account_name }}</span
                >
                <span v-else>--</span>
              </template>
            </hb-data-table>
          </template>
        </hb-form>
        <hb-form label="Journal Templates" extra-large full>
          <template v-slot:display>
              <div v-for="(accounting_event, index) in accounting_events" :key="`accounting_event_book_${accounting_event.book_id}`" :class="{ 'mt-6' : index > 0 }">
                <v-row class="mb-0" no-gutters>
                  <v-col cols="12" class="d-flex" v-if="accounting_event.accountingTypeName">
                    <span class="hb-text-light text-capitalize">{{accounting_event.accountingTypeName}} Journal</span>
                    <hb-tooltip>
                      <template slot="header">
                        <span class="text-capitalize">{{accounting_event.accountingTypeName}} Journal</span>
                      </template>
                      <template slot="body">
                        <span> {{ accounting_event.accountingTypeName === 'accrual book' ? 'An' : 'A' }} {{accounting_event.accountingTypeName}} journal follows the </span>
                        <br>
                        <span>rules of {{accounting_event.accountingTypeName.split(' ')[0] }} accounting. </span>
                        
                      </template>
                    </hb-tooltip>
                  </v-col>
                </v-row>
                <div>
                  <journals
                    :events="accounting_event.events"
                    :isEditable="false"
                    class="mb-2"
                  ></journals>
                </div>
              </div>
          </template>
        </hb-form>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import Journals from "../Accounting/Journals.vue";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PropertyAccountingTemplates",
  data() {
    return {
      active: "",
      propertyTemplate: {},
      headers: [
        { text: "Default Subtype", value: "gl_subtype_name" },
        { text: "Default Account", value: "gl_account_code", width: 200 },
      ],
      defaultCOAData: [],
      events: [],
      accountingType: {},
      accounting_events: [],
      isPropertySelected: false,
    };
  },
  props: ["property"],
  mixins: [notificationMixin],
  components: {
    Journals,
  },
  mounted() {
    this.isPropertySelected = this.property?.id ? true : false;
  },
  created() {
    this.getTemplates();
  },
  computed: {
    ...mapGetters({
      accountingTemplates: "accountingStore/templates",
      propertyAccountingTemplate: "propertiesStore/propertyAccountingTemplate",
      selectedTemplate: "accountingStore/selectedTemplate",
    }),
  },
  methods: {
    ...mapActions({
      getTemplates: "accountingStore/getTemplates",
      fetchPropertyAccountingTemplate:
        "propertiesStore/fetchPropertyAccountingTemplate",
      getAccountingTemplate: "accountingStore/getAccountingTemplate",
      setPropertyAccountingTemplate: "propertiesStore/setPropertyAccountingTemplate",
    }),
    editHasStarted(label) {},
    handleEmittedCancel() {},
    async handleEmittedSave() {
      if(this.propertyTemplate.id != this.propertyAccountingTemplate.id) {
        await this.setPropertyAccountingTemplate({
          property_id: this.property?.id,
          accounting_template_id: this.propertyTemplate.id
        });
        await this.getAccountingTemplate({ id: this.propertyTemplate.id });
        this.defaultCOAData = JSON.parse(
          JSON.stringify(this.selectedTemplate?.DefaultSubTypeAccounts)
        );
        this.events = JSON.parse(
          JSON.stringify(this.selectedTemplate?.GlEvents)
        );
        await this.fetchJournalEvents();
        this.showMessageNotification({
          type: "success",
          description: "Accounting template updated for property"
        });
      }
      this.active = "saved";
    },
    async fetchJournalEvents(){
        this.accountingType = this.selectedTemplate.AccountingSetup;
        if(this.accountingType.book_id === '0' ) {
          this.accounting_events = [{
            book_id: 0,
            events: this.events && this.events.filter(e=> e.book_id == '0'),
            accountingTypeName: 'cash book'
          }]
        } else if(this.accountingType.book_id === '1' ) {
          this.accounting_events = [{
            book_id: 1,
            events: this.events && this.events.filter(e=> e.book_id == '1'),
            accountingTypeName: 'accrual book'
          }]
        } else if(this.accountingType.book_id === '2' ) {
          let cashEvents = this.events && this.events.filter(e=> e.book_id == '0');
          let accrualEvents = this.events && this.events.filter(e=> e.book_id == '1'); 
          
          this.accounting_events = [];
          if(cashEvents.length > 0) {
            this.accounting_events.push({
              book_id: 0,
              events: cashEvents,
              accountingTypeName: 'cash book'
            });
          }

          if(accrualEvents.length > 0) {
            this.accounting_events.push({
              book_id: 1,
              events: accrualEvents,
              accountingTypeName: 'accrual book'
            });
          }
        }
      },
  },
  watch: {
    async property(val) {
      if (val?.id) {
        await this.fetchPropertyAccountingTemplate({
          id: val.id,
        });
        this.propertyTemplate = JSON.parse(
          JSON.stringify(this.propertyAccountingTemplate)
        );
        await this.getAccountingTemplate({ id: this.propertyTemplate.id });
        if(this.selectedTemplate?.AccountingSetup) {
          this.defaultCOAData = JSON.parse(
            JSON.stringify(this.selectedTemplate?.DefaultSubTypeAccounts)
          );
          this.events = JSON.parse(
            JSON.stringify(this.selectedTemplate?.GlEvents)
          );
          await this.fetchJournalEvents();
        }
        this.isPropertySelected = true;
      } else {
        this.isPropertySelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
